<template>
  <div class="admin" v-loading="loading" element-loading-text="上传中，请稍后~">
    <div class="mianbao">
      <div class="breadcrumb">
        <span style="color: #fff"> 综合除治> </span>
        <span style="color: #016ae0"> 除治详情 </span>
      </div>
    </div>
    <div class="search">
      <div class="top">
        疫木编号：
        <a-input
          v-model="form.treeId"
          style="
            width: 13%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入编号"
        ></a-input>
        创建时间：
        <a-range-picker
          :placeholder="['开始时间', '结束时间']"
          style="margin-right: 2%"
          @change="onChange"
        />
        作业人员：
        <a-select
          allowClear
          v-model="form.groupId"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option
            :value="item.groupId"
            v-for="(item, index) in groupList"
            :key="index"
          >
            {{ item.groupName }}
          </a-select-option>
        </a-select>
        村名：
        <a-input
          v-model="form.address"
          style="
            width: 12%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入村名"
        ></a-input>
        是否验收：
        <a-select
          allowClear
          v-model="form.isAccept"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option :value="0">未验收</a-select-option>
          <a-select-option :value="1">已验收</a-select-option>
        </a-select>
        是否注药：
        <a-select
          allowClear
          v-model="form.isInjection"
          style="
            width: 120px;
            background-color: transparent;
          "
        >
          <a-select-option :value="0">未注药</a-select-option>
          <a-select-option :value="1">已注药</a-select-option>
        </a-select>
      </div>
      <div class="bottom">
        林业小班：
        <a-input
          v-model="form.treeClass"
          style="
            width: 13%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入林业小班"
        ></a-input>
        是否取样：
        <a-select
          allowClear
          v-model="form.sample"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option :value="0">未取样</a-select-option>
          <a-select-option :value="1">已取样</a-select-option>
        </a-select>
        小班匹配状态：
        <a-select
          allowClear
          v-model="form.matchingTreeClass"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option :value="1">已匹配</a-select-option>
          <a-select-option :value="0">未匹配</a-select-option>
        </a-select>
        是否认定：
        <a-select
          allowClear
          v-model="form.identify"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="0">否</a-select-option>
        </a-select>
        上传用户：
        <a-input
          v-model="form.createBy"
          style="
            width: 12%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入上传用户"
        ></a-input>
        <!-- <span v-if="">
          图像上传：
         <a-select
           allowClear
           v-model="form.hasImg"
           style="
             width: 120px;
             margin-right: 40px;
             background-color: transparent;
           "
         >
           <a-select-option :value="1">已上传</a-select-option>
           <a-select-option :value="0">未上传</a-select-option>
         </a-select>
        </span> -->
        <a-button type="primary" :disabled="disabled" @click="handleLists"
          >查询</a-button
        >
        <!-- <a-button style="margin-left:1rem;" @click="resetClick">重置</a-button> -->
      </div>
    </div>
    <div class="content">
      <div class="erweima">
        <div class="list">
          <a-spin class="spin" :spinning="spinning" />
          <div style="display: flow-root; height: 42px; width: 100%">
            <div class="l_list">疫木除治列表</div>
            <div class="l_column">
              <a-button
                v-if="form.projectIdList === 11 || form.projectIdList === '11'"
                class="l_derive"
                @click="testClick"
                >批量验收</a-button
              >
              <a-button class="l_sort" @click="sortClick">
                <div class="l_type">
                  <div>排序</div>
                  <div>
                    <div class="l_up"><a-icon type="caret-up" /></div>
                    <div class="l_below"><a-icon type="caret-down" /></div>
                  </div>
                </div>
              </a-button>
              <a-button class="l_derive" @click="deriveClick"
                >导出报表</a-button
              >
              <a-button
                class="l_derive"
                style="margin-left: 10px"
                @click="collectClick"
                >导出汇总</a-button
              >
              <a-button
                class="l_derive"
                style="margin-left: 10px"
                @click="imgClick"
                >导出图像</a-button
              >
              <el-upload
                  class="upload-demo"
                  v-if="eliminationImport == '1'"
                  :action="this.api + '/elimination/importExcel'"
                  :headers="headers"
                  :show-file-list="false"
                  :on-progress="handleChange"
                  :on-success="successChange"
                  :on-error="errorChange">
                    <a-button
                      class="l_derive"
                      style="margin-left: 10px"
                      >批量导入</a-button 
                    >
              </el-upload>
            </div>
          </div>
          <vue-scroll :ops="ops" style="width: 100%">
            <Table :list="list" />
            <div class="fenye">
              <span style="margin-right: 15px">共{{ total }}条</span>
              <a-config-provider :locale="locale">
                <a-pagination
                  @change="onShowSizeChange"
                  @showSizeChange="showSizeChange"
                  v-model="form.pageNum"
                  :pageSizeOptions="pageSizeOptions"
                  :pageSize="form.pageSize"
                  :total="total"
                  show-less-items
                  :showSizeChanger="true"
                />
              </a-config-provider>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <!-- 项目查询 -->
    <Cebian
      :show="show"
      @handleShow="handleShow"
      @handleProject="handleProject"
    />
    <!-- 验收配置 -->
    <el-dialog
      title="验收配置"
      :visible.sync="opens"
      width="626px"
      style="margin-top: 6vh"
      append-to-body
    >
      <div>
        <el-transfer
          filterable
          :titles="['未验收', '验收表']"
          :filter-method="filterMethod"
          filter-placeholder="请输入搜索内容"
          v-model="treeIds"
          :data="data"
          @change="filterChange"
        >
        </el-transfer>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForms">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import Cebian from "../../components/cebian.vue"; //项目查询
import Table from "./components/table.vue"; // 除治列表
import axios from "axios";
export default {
  inject: ["reload"],
  data() {
    return {
      token: "",
      groupIdList: sessionStorage.getItem("groupId"), // 工作组id
      username: sessionStorage.getItem("username"),
      eliminationImport: sessionStorage.getItem("eliminationImport"),
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      loading: false,
      show: false, //项目查询展示/隐藏
      form: {
        //查询信息
        pageNum: 1,
        pageSize: 10,
        projectIdList: sessionStorage.getItem("projectId"),
        sample: '',
        matchingTreeClass: '',
        identify: '',
        createBy: '',
        treeClass: '',
        isInjection: '',
        isAccept: '',
        address: '',
        groupId: ''
      },
      total: 0, //列表总数
      lastPage: 1,
      locale: zhCN, //国际化 设置成中文
      pageSizeOptions: ["10", "15", "20", "25"],
      list: [], // 除治列表信息
      spinning: false, //数据加载动画
      groupList: [], //作业人员列表/工作组
      order: "ASC", //倒序/正序
      disabled: false, //点击查询时接口未执行完毕
      options: [], //地区
      //滚动条
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-y": "hidden",
        },
      },
      opens: false,
      data: null,
      treeIds: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
    };
  },
  components: {
    Cebian,
    Table,
  },
  mounted() {
    this.handleList();
    this.handleGroup();
    this.getArea(); // 地区
    this.token = sessionStorage.getItem("token");
  },
  methods: {
    // 获取工作组/作业人员
    handleGroup() {
      this.$axios(
        this.api + `/group/list?groupIdList=${this.groupIdList}`
      ).then((res) => {
        this.groupList = res.data.list;
      });
    },
    // 批量导入
    handleChange(file, fileList) {
      this.loading = true
    },
    successChange(response) {
      this.loading = false
      this.$message.success("上传成功");
    },
    errorChange(err) {
      this.loading = false
      this.$message.error('上传失败，请重试')
    },
    // 项目查询
    handleProject(e) {
      this.form.projectIdList = e;
      this.handleList();
    },
    handleShow(show) {
      this.show = show;
    },
    // 查询
    handleLists() {
      this.disabled = true;
      if (this.form.pageNum !== 1) {
        this.form.pageNum = this.lastPage;
        this.handleList();
      } else {
        this.handleList();
      }
    },
    //  疫木除治列表
    handleList() {
      this.spinning = true;
      this.$axios(this.api + "/elimination/list", { params: this.form }).then(
        (res) => {
          if (res.code === 200) {
            this.lastPage = res.data.pages;
            this.total = res.data.total;
            this.list = res.data.list;
            this.spinning = false;
            this.disabled = false;
          }
        }
      );
    },
    // 重置
    resetClick() {
      this.reload();
    },
    // 创建时间
    onChange(value, mode) {
      this.form.startTime = mode[0];
      this.form.endTime = mode[1];
    },
    // 分页
    onShowSizeChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.handleList();
    },
    // 每页几条？
    showSizeChange(current, size) {
      this.form.pageSize = size;
      this.handleList();
    },
    // 获取地区
    getArea() {
      this.$axios.get(this.api + "/project/search").then((res) => {
        if (res.code === 200) {
          this.options = this.listToTree(res.data);
        }
      });
    },
    listToTree(list, parentId = "") {
      return list
        .filter((item) => item.parentId === parentId)
        .map((item) => ({
          ...item,
          label: item.orgName,
          value: item.orgName,
          children: this.listToTree(list, item.id),
        }));
    },
    // 验收
    testClick() {
      let form = this.form;
      let data = {
        projectIdList: form.projectIdList,
        treeId: form.treeId,
        startTime: form.startTime,
        endTime: form.endTime,
        treeClass: form.treeClass,
        groupId: form.groupId,
        sample: form.sample,
        identify: form.identify,
        address: form.address,
        createBy: form.createBy,
        hasImg: form.hasImg,
        isAccept: 0,
        matchingTreeClass: form.matchingTreeClass,
      };
      this.$axios(this.api + "/elimination/select/unaccept", {
        params: data,
      }).then((res) => {
        if (res.code === 200) {
          const generateData = (_) => {
            const data = [];
            const cities = res.data;
            cities.forEach((item, index) => {
              data.push({
                label: item.treeId,
                key: item.treeId,
                sort: index + 1,
              });
            });
            return data;
          };
          this.data = generateData();
          this.opens = true;
        }
      });
    },
    filterChange(value, direction, movedKeys) {
      this.treeIds = value;
      this.$forceUpdate();
    },
    async submitForms() {
      if (this.treeIds === undefined || this.treeIds.length === 0) {
        return this.$message.info("未选择数据，请选择后提交");
      }
      let data = {
        treeIds: this.treeIds,
        accept: 1,
      };
      const res = await this.$axios.put(
        this.api + "/elimination/batch/update/accept",
        data
      );
      if (res.code === 200) {
        this.$message.success("已验收，请手动更新");
        this.opens = false;
      } else {
        this.$message.info("提交失败，请重试");
      }
    },
    cancel() {
      this.opens = false;
      this.treeIds = [];
      this.data = null;
    },
    // 排序
    async sortClick() {
      let form = this.form;
      if (this.order === "ASC") {
        this.order = "DESC";
        form.order = this.order;
        const res = await this.$axios.get(this.api + "/elimination/list", {
          params: form,
        });
        return (this.list = res.data.list);
      } else if (this.order === "DESC") {
        this.order = "ASC";
        form.order = this.order;
        const res = await this.$axios.get(this.api + "/elimination/list", {
          params: form,
        });
        return (this.list = res.data.list);
      }
    },
    // 导出报表
    deriveClick() {
      let form = this.form;
      axios({
        method: "get",
        url: this.api + "/elimination/downloadExcel", //'/api_request'+
        headers: {
          Authorization: this.token,
        },
        params: {
          projectId: form.projectIdList,
          treeId: form.treeId,
          startTime: form.startTime,
          endTime: form.endTime,
          treeClass: form.treeClass,
          groupId: form.groupId,
          username: this.username,
          order: this.order,
          sample: form.sample,
          isInjection: form.isInjection,
          identify: form.identify,
          address: form.address,
          hasImg: form.hasImg,
          createBy: form.createBy,
        }, // 请求参数
        responseType: "blob", // 表明返回服务器返回的数据类型
      }).then((res) => {
        // 处理返回的文件流
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel", //excel文件
        });
        let fileName = "疫木除治信息.xlsx"; //文件名称
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        this.$message.success("下载成功");
        link.click();
      });
    },
    // 导出汇总
    collectClick() {
      let form = this.form;
      axios({
        method: "get",
        url: this.api + "/elimination/collect",
        headers: {
          Authorization: this.token,
        },
        params: {
          projectId: form.projectIdList,
          startTime: form.startTime,
          endTime: form.endTime,
        }, // 请求参数
        responseType: "blob", // 表明返回服务器返回的数据类型
      }).then((res) => {
        // 处理返回的文件流
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel", //excel文件
        });
        let fileName = "汇总信息.xlsx"; //文件名称
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        this.$message.success("下载成功");
        link.click();
      });
    },
    // 导出图像
    imgClick() {
      let form = this.form;
      if (this.form.address === undefined) {
        return this.$message.info("请输入村名");
      } else {
        axios({
          method: "get",
          url: this.api + "/elimination/hy/img/download",
          headers: {
            Authorization: this.token,
          },
          params: {
            address: form.address 
          }, // 请求参数
          responseType: "blob", // 表明返回服务器返回的数据类型
        }).then((res) => {
          // 处理返回的文件流
          let blob = new Blob([res.data], {
					  type: "application/zip" //文件为zip格式
				  });
          if(blob.size < 79 || blob.size === 79) {
            return this.$message.info('该村庄下未查询到除治图像数据')
          } else {
            let contentDisposition = res.headers['content-disposition'];
            // 运用window.decodeURI来解码，解决中文乱码问题
            let fileName = window.decodeURI(contentDisposition.substring(contentDisposition.indexOf('=')+1)) 
			      let url = window.URL.createObjectURL(blob)
			      let rotate = document.createElement('a')
			      rotate.style.display = 'none'
			      rotate.href = url
            rotate.setAttribute('download', fileName) //获取文件名称
			      document.body.appendChild(rotate)
            this.$message.success("下载成功");
			      rotate.click()
          }
        });
      }
    },
  },
};
</script>

<style scoped="scoped">
div /deep/.ant-pagination-item-ellipsis {
  color: #fff !important;
}
div /deep/.ant-select-selection {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-input {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-select-arrow {
  color: rgb(249 240 240 / 91%);
}
div /deep/.ant-calendar-range-picker-separator {
  color: rgb(249 240 240 / 91%);
}
div /deep/.duoxuan > div[data-v-21316321] {
  background-color: rgba(240, 240, 240, 0.1);
  color: #ffffff;
}
.admin {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
.mianbao {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
}
.search {
  width: 100%;
  height: 140px;
  border: 1px solid #016ae0;
  border-radius: 8px;
  color: #ffffff;
  /* background-color: #fff; */
  margin-top: 10px;
  text-align: left;
  /* display: flex; */
  /* align-items: center; */
  padding: 0 20px;
}
.search > div {
  height: 70px;
  display: flex;
  align-items: center;
}
.content {
  width: 100%;
  height: calc(100% - 210px);
  /* background-color: #fff; */
  border: 1px solid #016ae0;
  border-radius: 8px;
  margin-top: 20px;
  padding: 11px;
  color: #ffffff;
}
.button {
  text-align: left;
}
.erweima {
  width: 100%;
  /* margin-top: 8px; */
  height: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 40px);
  /* position: relative; */
}
.spin {
  position: absolute;
  top: 40%;
  left: 50%;
}
.item {
  width: 23.5%;
  height: calc(50% - 21px);
  margin-bottom: 10px;
}
.fenye {
  height: 32px;
  margin-top: 8px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.yuan {
  width: 15px;
  height: 15px;
  border: 1px solid #005ea1;
  border-radius: 50%;
}
.item_center {
  border: 1px solid #005ea1;
  width: 100%;
  margin-top: 15px;
  height: calc(100% - 30px);
}
.number {
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: 18px;
}
.img {
  border-top: 1px solid #005ea1;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.img img {
  height: 80%;
  width: auto;
}
/* ------------------------------------------ */
.l_list {
  display: flex;
  float: left;
  align-items: center;
  padding: 0 0rem 10px 4px;
  height: 100%;
  font-size: 16px;
}
.l_column {
  padding: 0 10px 10px 0;
  display: flex;
  float: right;
  align-items: center;
  height: 100%;
}
.l_type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}
.l_column .l_sort {
  margin: 0 1rem;
  border: 1px solid #0099ff;
  background: transparent;
  color: #0099ff;
}
.l_column .l_derive {
  color: #ffffff;
  border: 1px solid #0099ff;
  background: #0066ff;
}
.l_up {
  height: 10px;
}
.l_below {
  height: 19px;
}
div /deep/ .el-dialog {
  margin-top: 1vh !important;
}
div /deep/ .el-transfer-panel__body {
  height: 61vh;
}
div /deep/ .el-transfer-panel__list.is-filterable {
  height: 56vh;
}
div /deep/ .upload-demo .el-upload--picture-card {
  background-color: transparent; 
  border: none;
  width: auto;
  height: auto;
  line-height: 0;
}
</style>
