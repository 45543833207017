<template>
  <div class="table">
    <div class="header">
      <!-- <div class="duoxuan">
			</div> -->
      <div class="list">
        <div
          class="item"
          :style="{ width: item.width + '%' }"
          v-for="(item, index) in columns"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="table_body">
      <div class="body" v-for="item in list" :key="item.treeId">
        <!-- <div class="duoxuan">
				</div> -->
        <div class="list">
          <div
            class="item"
            style="width: 7%"
            v-clipboard:copy="item.treeId"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeId }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.personnel"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.personnel }}
          </div>
          <div
            class="item"
            style="width: 6%"
            v-clipboard:copy="item.longitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ (item.longitude-0).toFixed(7) }}
          </div>
          <div
            class="item"
            style="width: 6%"
            v-clipboard:copy="item.latitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ (item.latitude-0).toFixed(6) }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.diameter"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ (item.diameter-0).toFixed(0) }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.address"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.address }}
          </div>
          <div
            class="item"
            style="width: 8%"
            v-clipboard:copy="item.treeClass"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeClass }}
          </div>
          <div class="item" style="width: 5%">
            {{ item.sample | sampleChange }}
          </div>
          <div class="item" style="width: 5%">
            {{ item.identify | identifyChange }}
          </div>
          <div class="item" style="width: 5%">
            {{ item.isInjection | isInjectionChange }}
          </div>
          <div class="item" :style="item.isAccept === 1 ? 'width: 5%;color:#06d5ff;' : 'width: 5%;color:#ff0000;'">
            {{ item.isAccept | isAcceptChange }}
          </div>
          <div class="item" style="width: 10%">
            {{ item.projectName }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.createTime"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.createTime }}
          </div>
          <div class="item" style="width: 7%">
            {{ item.groupName }}
          </div>
          <div class="item" style="width: 7%">
            <div
              class="yuan"
              :style="{
                'background-color':
                  item.status == 0
                    ? '#ff0000'
                    : item.status == '2'
                    ? '#09f'
                    : '#09f',
              }"
            ></div>
            {{
              item.status == "0"
                ? "未除治"
                : item.status == "2"
                ? "已除治"
                : "已除治"
            }}
          </div>
          <div class="item" :style="item.examine === 1 ? 'width: 5%;color:#06d5ff;' : 'width: 5%;color:#ff0000;'">
            {{ item.examine === 1 ? '已通过' : '未通过' }}
          </div>
          <div class="item" style="width: 7%">
            {{ item.examineUser }}
          </div>
          <div class="item remark" style="width: 6%">
            {{ item.remark }}
          </div>
          <div class="item item1" style="width: 10%;display: block;">
            <div class="yi" v-if="item.status == '0'">
              <div class="" style="color: #ff0000">未上传</div>
              <div class="" style="color: #999">暂无</div>
            </div>
            <div class="wei" v-else style="display: flex;justify-content: space-evenly;">
              <div
                class=""
                style="color: #09f;"
                @click="handleDetail(item)"
              >
                过程
              </div>
              <div class="" @click="handleResult(item)">结果</div>
              <div
                class=""
                v-if="groupIdList[0] == 1 || modify === '1'"
                style="color: #09f"
                @click="amendClick(item)"
              >
                修改
              </div>
              <div>
                <a-popconfirm
                  v-if="groupIdList[0] == 1"
                  style="display: flex; align-items: center"
                  title="确认删除"
                  placement="bottom"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="deleteClick(item.treeId)"
                >
                  删除
                </a-popconfirm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: left"
    >
      <el-form ref="form" :model="amend" label-width="80px">
        <el-form-item label="疫目编号">
          <el-input style="width: 80%" v-model="amend.treeId"></el-input>
        </el-form-item>
        <el-form-item label="经度">
          <el-input style="width: 80%" v-model="amend.longitude"></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input style="width: 80%" v-model="amend.latitude"></el-input>
        </el-form-item>
        <el-form-item label="地径">
          <el-input style="width: 80%" v-model="amend.diameter"></el-input>
        </el-form-item>
        <el-form-item label="作业人员">
          <el-select
            v-model="amend.groupName"
            @change="onChange"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item.groupId"
              :label="item.groupName"
              v-for="(item, index) in groupList"
              :key="index"
            >
              {{ item.groupName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="林业小班">
          <el-input style="width: 80%" v-model="amend.treeClass"></el-input>
        </el-form-item>
        <el-form-item label="核验" v-if="amend.projectId === 11 || amend.projectId === '11'">
          <el-radio-group v-model="amend.examine" :disabled="amend.examineBy === null ? false : true">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">未通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否取样">
          <el-radio-group v-model="amend.sample">
            <el-radio :label="1">已取样</el-radio>
            <el-radio :label="0">未取样</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否认定">
          <el-radio-group v-model="amend.identify">
            <el-radio :label="1">已认定</el-radio>
            <el-radio :label="0">未认定</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否注药">
          <el-radio-group v-model="amend.isInjection">
            <el-radio label="1">已注药</el-radio>
            <el-radio label="0">未注药</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :disabled="disableds" :rows="2" style="width: 80%" v-model="amend.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: Array
  },
  inject: ["reload"],
  data() {
    return {
      dialogVisible: false,
      amend: {},
      disableds: true,
      columns: [
        {
          title: "疫木编号",
          dataIndex: "treeId",
          key: "treeId",
          width: "7",
        },
        {
          title: "上传用户",
          dataIndex: "personnel",
          key: "personnel",
          width: "4",
        },
        {
          title: "经度",
          dataIndex: "longitude",
          key: "longitude",
          width: "6",
        },
        {
          title: "纬度",
          dataIndex: "latitude",
          key: "latitude",
          width: "6",
        },
        {
          title: "地径(cm)",
          dataIndex: "diameter",
          key: "diameter",
          width: "4",
        },
        {
          title: "村名",
          dataIndex: "address",
          key: "address",
          width: "4",
        },
        {
          title: "林业小班",
          key: "treeClass",
          dataIndex: "treeClass",
          width: "8",
        },
        {
          title: "是否取样",
          key: "sample",
          dataIndex: "sample",
          width: "5",
        },
        {
          title: "是否认定",
          key: "identify",
          dataIndex: "identify",
          width: "5",
        },
        {
          title: "是否注药",
          key: "isInjection",
          dataIndex: "isInjection",
          width: "5",
        },
        {
          title: "是否验收",
          key: "isAccept",
          dataIndex: "isAccept",
          width: "5",
        },
        {
          title: "所属项目",
          key: "projectName",
          dataIndex: "projectName",
          width: "10",
        },
        {
          title: "创建时间",
          key: "createTime",
          dataIndex: "createTime",
          width: "10",
        },
        {
          title: "作业人员",
          key: "groupName",
          dataIndex: "groupName",
          width: "7",
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          width: "7",
        },
        {
          title: "核验状态",
          key: "examine",
          dataIndex: "examine",
          width: "5",
        },
        {
          title: "核验者",
          key: "examineUser",
          dataIndex: "examineUser",
          width: "7",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          width: "6",
        },
        {
          title: "操作",
          key: "action",
          dataIndex: "tags",
          width: "10",
        },
      ],
      groupList: [],
      groupIdList: sessionStorage.getItem("groupId"),
      modify: sessionStorage.getItem("modify"),
      treeOldIdz: "", //旧id
    };
  },
  filters: {
    sampleChange(e) {
      return e == 1 ? "已取样" : "未取样";
    },
    identifyChange(e) {
      return e == 1 ? "已认定" : "未认定";
    },
    isInjectionChange(e) {
      return e == 1 ? "已注药" : "未注药";
    },
    isAcceptChange(e) {
      return e == 1 ? "已验收" : "未验收";
    },
  },
  methods: {
    // 复制成功
    onCopy() {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError() {
      this.$message.info("复制失败");
    },
    handleDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {
          treeId: item.treeId,
        },
      });
    },
    handleResult(item) {
      this.$router.push({
        path: "/result",
        query: {
          treeId: item.treeId,
        },
      });
    },
    // 获取作业人员列表
    handleGroup() {
      this.$axios(
        this.api + `/group/list?groupIdList=${this.groupIdList}`
      ).then((res) => {
        this.groupList = res.data.list;
      });
    },
    onChange(e) {
      this.amend.groupId = e;
    },
    // 修改
    amendClick(e) {
      this.treeOldId = e.treeId;
      this.amend = e;
      this.dialogVisible = true;
      if(this.groupIdList === '1') {
        return this.disableds = false
      } else if(this.amend.examineBy === null) {
        return this.disableds = false
      } else {
        return this.disableds = true
      }
    },
    // 删除
    async deleteClick(e) {
      let treeId = e;
      const res = await this.$axios.delete(
        this.api + "/elimination/delete/" + treeId
      );
      if (res.code === 200) {
        this.$message.success("已删除，请手动更新");
      } else {
        this.$message.info("删除失败，请重试");
      }
    },
    async affirmClick() {
      let data = {
        treeId: this.amend.treeId,
        treeOldId: this.treeOldId,
        longitude: this.amend.longitude,
        latitude: this.amend.latitude,
        groupId: this.amend.groupId,
        treeClass: this.amend.treeClass,
        diameter: this.amend.diameter,
        remark: this.amend.remark,
        examine: this.amend.examine,
        sample: this.amend.sample,
        identify: this.amend.identify,
        isInjection: this.amend.isInjection
      };
      this.$message.success("提交中，请稍后~");
      if(this.amend.longitude !== '' && this.amend.latitude !== '') {
          const res1 = await this.$axios.get(
            this.api + `/elimination/treeClass?longitude=${this.amend.longitude}&latitude=${this.amend.latitude}&projectId=${this.amend.projectId}`,
          );
          if (res1.code === 200) {
            if(res1.data !== '') {
              data.treeClass = res1.data
              this.updateClick(data)
            } else {
              data.treeClass = this.amend.treeClass
              this.updateClick(data)
            }
          } else {
            data.treeClass = this.amend.treeClass
            this.updateClick(data)
          }
        } else {
           data.treeClass = this.amend.treeClass
           this.updateClick(data)
        }
    },
    async updateClick(data) {
      const res = await this.$axios.put(
        this.api + "/elimination/updateTreeId",
        data
      );
      if (res.code === 200) {
        this.dialogVisible = false;
        this.reload();
        this.$message.success("提交成功，请手动更新");
      } else {
        this.dialogVisible = false;
        this.$message.info("更新失败，请重试");
      }
    },
  },
  mounted() {
    this.handleGroup();
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.yuan {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}
/* .item1 > div {
  display: flex;
} */
.yi > div,
.wei > div {
  margin-right: 0px;
  /* width: 4em; */
}
.wei > div:hover {
  cursor: pointer;
}
.duoxuan {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.duoxuan > div {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.header {
  display: flex;
  height: 50px;
  background-color: #1f325e;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.header .list {
  background-color: #1f325e;
  width: calc(100% - 0px);
  display: flex;
  align-items: center;
}
.table_body {
  height: calc(100% - 50px);
  /* overflow-y: auto; */
}
.table_body .body {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.table_body .list {
  /* background-color:#1f325e ; */
  width: calc(100% - 0px);
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remark {
  overflow: auto;
  display: block;
}
::-webkit-scrollbar {
    width: 8px;          /* 纵向滚动条 宽度 */
    height: 8px;         /* 横向滚动条 高度 */
    background-color: #c9cdd44f;     /* 整体背景 */
    border-radius: 10px;  /* 整体 圆角 */
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9cdd4;
}
.t-job >>> .ant-select-selection {
  background-color: transparent;
  color: #606266;
  height: 40px;
}
.t-job >>> .ant-select-selection__rendered {
  line-height: 40px;
}
</style>
